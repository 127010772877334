<script setup lang="ts">
const props = defineProps<{
  value: string | number
  hideCountryCode?: boolean
}>()

const formatted = computed(() => formatNorwegianPhoneNumber(props.value, props.hideCountryCode))
</script>

<template>
  <component :is="formatted.digits ? 'a' : 'span'" data-dd-privacy="mask" :href="formatted.digits ? `tel:${formatted.digits}` : ''">
    {{ formatted.text || String(value) }}
  </component>
</template>
