import consola from 'consola'

export function formatNorwegianPhoneNumber(input: string | number, hideCountryCode?: boolean) {
  const digits = String(input).replace(/\D/g, '')

  switch (digits.length) {
    case 3:
    case 4:
    case 5:
      // No formatting
      return { digits, text: digits }
    case 6:
      return { digits, text: digits.replace(/(\d{3})(\d{3})/, '$1 $2') }
    case 10:
      // We likely have a country code, prefix with 00 and + respectively
      return {
        digits: `+${digits}`,
        text: format8or10digitPhoneNumber(digits, hideCountryCode),
      }
    case 8:
      return { digits, text: format8or10digitPhoneNumber(digits, hideCountryCode) }
    default:
      consola.warn('Invalid phone number', input)
      return { digits: null, text: null }
  }
}

export function format8or10digitPhoneNumber(digits: string, hideCountryCode?: boolean) {
  let value = digits
  let countryCode: string | undefined
  if (digits.length === 10) {
    countryCode = value.slice(0, 2)
    value = value.slice(2)
  }
  else if (digits.length !== 8) {
    throw new Error(`Invalid input to formatRegularPhoneNumber: ${digits}`)
  }

  const useMobileFormatting = ['4', '8', '9'].includes(value[0]!)

  const formatted = useMobileFormatting
    ? value.replace(/(\d{3})(\d{2})(\d{3})/, '$1 $2 $3')
    : value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4')

  return (countryCode && !hideCountryCode) ? `+${countryCode} ${formatted}` : formatted
}
